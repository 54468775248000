const getEnvironment = () => {
    switch (process.env.REACT_APP_BACKEND_URL) {
        case 'https://chat-api-stage.triv.io':
          return 'staging';
        case 'https://chat-api.triv.io':
            return 'production'
        default:
          return 'development'
      }
}

export default getEnvironment;